import { prefetchPropertiesMostVisited } from 'api/queries/fetchPropertiesMostVisited'
import { ContactUs } from 'components/ContactUs/ContactUs'
import { Hero } from 'components/Hero/Hero'
import { PageLayout } from 'components/PageLayout/PageLayout'
import { Testimonials } from 'components/Testimonals/Testimonials'
import { useTranslation } from 'hooks/useTranslation'
import { GetServerSideProps } from 'next'
import { Partners } from 'containers/Landing/components/Partners/Partners'
import { Routes } from 'services/router/Routes'
import { Articles } from './components/Articles/Articles'
import { Housing } from './components/Housing/Housing'
import { HowSmarthousingWorks } from './components/HowSmarthousingWorks/HowSmarthousingWorks'
import { WhySmartHousing } from './components/WhySmarthousing/WhySmarthousing'
import styles from './Landing.module.scss'
import { Statistics } from './components/Statistics/Statistics'

export const Landing = () => {
  const { t } = useTranslation()

  const testimonials = [
    {
      name: t('home:testimonials.list.quotes.title'),
      customText: t('home:testimonials.list.quotes.subtitle'),
      quote: t('home:testimonials.list.quotes.first'),
      thumb: {
        imageUrl: '/images/testimonials/images/families.png',
      },
      isSingular: true,
    },
  ]

  const statistics = [
    {
      value: t('home:statistics.items.first.value'),
      description: t('home:statistics.items.first.description'),
    },
    {
      value: t('home:statistics.items.second.value'),
      description: t('home:statistics.items.second.description'),
    },
    {
      value: t('home:statistics.items.third.value'),
      description: t('home:statistics.items.third.description'),
    },
  ]

  return (
    <PageLayout footerClass={styles.footerClass}>
      <Hero
        fixedActionButton
        title={t('home:hero.title')}
        subtitle={t('home:hero.subtitle')}
        imgSrc="/images/cover-image.webp"
        buttonText={t('home:button-find-house')}
        buttonLink="/wizard"
      />

      <HowSmarthousingWorks />

      <WhySmartHousing />

      <Statistics title={t('home:statistics.title')} items={statistics} />

      <Testimonials
        title={t('home:testimonials.title')}
        subtitle={t('home:testimonials.subtitle')}
        testimonials={testimonials}
        buttonLink={Routes.reviews()}
        buttonText={t('home:testimonials.button')}
      />

      <Housing />

      <Articles />

      <Partners title={t('home:partners.our-friends')} />

      <ContactUs
        callNumber={{ title: '+34 911 983 336', phoneNumber: '+34911983336' }}
        whatsappNumber={{ title: '+34 627 065 603', phoneNumber: '34627065603' }}
      />
    </PageLayout>
  )
}

export const getLandingServerSideProps: GetServerSideProps = async () => {
  const properties = await prefetchPropertiesMostVisited()

  return {
    props: {
      ...properties,
    },
  }
}
